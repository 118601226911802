import { render, staticRenderFns } from "./SubHeader.vue?vue&type=template&id=198f83aa&scoped=true&v-slot%3Aextension=true&"
import script from "./SubHeader.vue?vue&type=script&lang=js&"
export * from "./SubHeader.vue?vue&type=script&lang=js&"
import style0 from "./SubHeader.vue?vue&type=style&index=0&id=198f83aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198f83aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAppBar,VBtn,VIcon,VTab,VTabs})
