<template>
  <v-container class="d-flex flex-column align-center justify-center mb-16">
    <div class="news-title underlined">Новости</div>
    <div
      class="d-flex"
      :class="
        $vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column align-center'
      "
    >
      <div v-for="card in news" :key="card.id">
        <New :post="card" />
      </div>
    </div>
  </v-container>
</template>

<script>
import New from "./New.vue";
export default {
  data() {
    return {
      news: [
        {
          id: 1,
          title: `НА СТРАХОВЫЕ ВЫПЛАТЫ МЕДИКАМ ЗА ЗАРАЖЕНИЕ COVID-19 ВЫДЕЛЯТ ЕЩЕ 3 МЛРД РУБЛЕЙ`,
          description: `Премьер-министр РФ Михаил Мишустин подписал распоряжение о выделении специальной социальной выплаты медицинским работникам – более 3,3 млрд рублей будет направлено на страховые выплаты сотрудникам больниц, которые помогают пациентам с коронавирусной инфекцией. Предыдущий транш в размере 4,103 млрд рублей на эти же цели правительство направило в конце марта 2022 года, он предназначался для начисления выплат до конца II квартала этого года.
Также Мишустин подписал документ о выделении более 2,5 млрд рублей работникам интернатов, домов престарелых и других социальных учреждений – за оказываемую помощь постояльцам, у которых диагностирован COVID-19.

Кроме того, 2,2 млрд рублей направят на надбавки медработникам силовых структур, также занятым в борьбе с коронавирусной инфекцией. Выплаты будут начисляться во II квартале 2022 года.

Распоряжения правительства о выделении средств для каждой из указанных групп работников были опубликованы 18 апреля 2022 года.`,
          link: "https://vademec.ru/news/2022/04/14/na-strakhovye-vyplaty-medikam-za-zarazhenie-covid-19-vydelyat-eshche-3-mlrd-rubley/",
        },
        {
          id: 2,
          title: `«Р-ФАРМ» ЗАРЕГИСТРИРУЕТ ПРЕПАРАТ ОТ ИДИОПАТИЧЕСКОГО РЕЦИДИВИРУЮЩЕГО ПЕРИКАРДИТА`,
          description: `ГК «Р-Фарм» 13 апреля объявила о завершении II и III фазы клинического исследования (КИ) препарата Арцерикс (прежнее название – RPH-104, МНН гофликицепт) среди пациентов с идиопатическим рецидивирующим перикардитом. Предполагается, что результаты КИ станут основой для регистрации препарата по такому показанию. Это не единственное заболевание, по которому «Р-Фарм» проводит КИ лекарственного средства.
II-III фаза КИ препарата по показанию «идиопатический рецидивирующий перикардит» проходила в России на базе пяти медучреждений с участием 35 пациентов.
В настоящее время продолжается КИ препарата на пациентах с острым инфарктом миокарда. В нем принимают участие 146 пациентов. Разрешение на его проведение компания получила в апреле 2020 года. С 2019 года также ведутся КИ препарата по показанию «колхицин-резистентная семейная средиземноморская лихорадка».`,
          link: "https://vademec.ru/news/2022/04/13/r-farm-zaregistriruet-preparat-ot-idiopaticheskogo-retsidiviruyushchego-perikardita/",
        },
        {
          id: 3,
          title: `НИЦЭМ ИМ. Н.Ф. ГАМАЛЕИ ОЦЕНИЛ ЭФФЕКТИВНОСТЬ ТЕХНОЛОГИИ ОБЕЗЗАРАЖИВАНИЯ ВОЗДУХА «ПОТОК» ДЛЯ БОРЬБЫ С ВОЗБУДИТЕЛЕМ COVID-19 В 99,99%`,
          description: `В марте 2020 года Правительство РФ распорядилось провести централизованную закупку бесконтактных термометров и обеззараживателей воздуха – предполагалось, что во время пандемии приборы должны снизить вероятность инфицирования вирусом. За два года рынок рециркуляторов, которые ранее применялись только в медучреждениях, вырос до 5 млрд рублей, однако их эффективность в отношении фильтрации воздуха от возбудителя COVID-19 досконально не изучалась. В апреле 2022 года НИЦЭМ им. Н.Ф. Гамалеи Минздрава провел первое исследование об эффективности применения обеззараживателя воздуха для уничтожения новой коронавирусной инфекции: установка «Поток 150-M-01» разработки научно-производственной компании «Поток Интер» за 25 минут снизила концентрацию имитатора коронавируса SARS-CoV-2 на 99,99%.`,
          link: "https://vademec.ru/news/2022/04/14/nitsem-im-n-f-gamalei-otsenil-effektivnost-tekhnologii-obezzarazhivaniya-vozdukha-potok-dlya-borby-s/",
        },
      ],
    };
  },
  components: {
    New,
  },
};
</script>

<style >
.news-title {
  font-weight: 700;
  font-size: 36px;
}
</style>