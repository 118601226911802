var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new px-6 px-md-10 py-16 mx-7 mb-8",staticStyle:{"position":"relative"},style:(_vm.$vuetify.breakpoint.lgAndUp
      ? 'width:350px; height:400px'
      : _vm.$vuetify.breakpoint.mdAndUp
      ? 'width:250px; min-height:300px;max-height:500px'
      : 'max-width:90%; min-height:250px;max-height:450px')},[_c('div',{staticClass:"new--title",style:(_vm.$vuetify.breakpoint.lgAndUp ? 'font-size: 18px' : 'font-size: 18px'),domProps:{"innerHTML":_vm._s(
      _vm.$vuetify.breakpoint.name == 'xl'
        ? _vm.post.title.slice(0, 80) + '...'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? _vm.post.title.slice(0, 70) + '...'
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? _vm.post.title.slice(0, 70) + '...'
        : _vm.$vuetify.breakpoint.name == 'xs'
        ? _vm.post.title.slice(0, 70) + '...'
        : _vm.post.title.slice(0, 70) + '...'
    )}}),_c('div',{staticClass:"mt-4 new--description",style:(_vm.$vuetify.breakpoint.lgAndUp ? 'font-size: 14px' : 'font-size: 14px'),domProps:{"innerHTML":_vm._s(
      _vm.$vuetify.breakpoint.name == 'xl'
        ? _vm.post.description.slice(0, 140) + '...'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? _vm.post.description.slice(0, 120) + '...'
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? _vm.post.description.slice(0, 150) + '...'
        : _vm.$vuetify.breakpoint.name == 'xs'
        ? _vm.post.description.slice(0, 150) + '...'
        : _vm.post.description.slice(0, 70) + '...'
    )}}),_c('v-btn',{staticStyle:{"position":"absolute","bottom":"10px","left":"50%","transform":"translateX(-50%)"},attrs:{"color":"green","text":"","outlined":"","href":_vm.post.link,"target":"_blank"}},[_vm._v("Подробнее")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }