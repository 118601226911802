var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper d-flex justify-center align-center flex-column py-6 my-md-16"},[_c('div',{staticClass:"underlined license-title"},[_vm._v("Лицензия")]),_c('div',{staticClass:"license px-16 py-10 rounded-lg",style:(_vm.$vuetify.breakpoint.lgAndUp
        ? 'margin-left: 100px'
        : 'display:flex;flex-direction:column; justify-content:center;align-items:center;')},[_c('img',{staticClass:"license-image my-4",style:(_vm.$vuetify.breakpoint.name == 'xl'
          ? 'transform: translate(-120%, -50%);position:absolute;top:50%'
          : _vm.$vuetify.breakpoint.name == 'lg'
          ? 'transform: translate(-105%, -50%);position:absolute;top:50%'
          : 'position:relative'),attrs:{"height":"320","src":require("../assets/license.jpg")},on:{"click":function($event){_vm.dialog = true}}}),(_vm.$vuetify.breakpoint.lgAndUp)?_c('span',[_vm._v(" Обладая большой компетенцией в построении фармацевтического бизнеса с нуля, наша компания помогает создать объекты фармацевтической отрасли ,и получить лицензии на оптовую фармацевтическую деятельность и перевозку медикаментов. Наши специалисты рады проконсультировать вас ,на любом этапе построения этого бизнеса :от регистрации юридического лицаи строительства складов,до финального получения фармацевтической лицензии и начала ведения бизнеса. ")]):_vm._e()]),_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp
        ? '500px'
        : _vm.$vuetify.breakpoint.smAndDown
        ? '80%'
        : '450px'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_c('v-img',{attrs:{"src":require("../assets/license.jpg")}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }